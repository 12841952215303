import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import hooks, { Link } from '../../globals/hooks';


export const IndexPage: React.FC = () => {
  const { linkId } = useParams<{ linkId: string }>()
  const [loading, setLoading] = React.useState(true)
  const [link, setLink] = React.useState<Link>()

  const init = async () => {
    setLoading(true)
    const linkResult = await hooks.getLinkById(linkId)
    if (!linkResult) {
      setTimeout(function () { document.location.href = "https://www.gogoo.app/" }, 250)
    }
    else setTimeout(function () { document.location.href = linkResult.applink; }, 250)

    setLink(linkResult)
    setLoading(false)
  }
  React.useEffect(() => { init() }, [])

  const handleClick = () => {
    var userAgent = navigator.userAgent || navigator.vendor
    if (/android/i.test(userAgent)) {
      return setTimeout(function () { document.location.href = "https://play.google.com/store/apps/details?id=app.gogoo.gogoo" }, 250);
    }
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return setTimeout(function () { document.location.href = "https://apps.apple.com/dk/app/gogoo/id1560958137" }, 250);
    }
    return setTimeout(function () { document.location.href = "https://www.gogoo.app/" }, 250)
  }

  if (!link || loading) return (
    <div className='container' style={{ textAlign: 'center' }}>
      <h1>Henter data...</h1>
    </div>

  )

  return (
    <div className='container'>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <h1>{link.title}</h1>
        <p>{link.description}</p>
        <button onClick={handleClick} style={{ marginBottom: 30, marginTop: 20, marginLeft: 'auto', marginRight: 'auto', paddingLeft: 80, paddingRight: 80 }}>{link.title}</button>
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <img src={link.image} alt={link.title} style={{ maxWidth: '90vw' }} />
        </div>
      </div>
    </div>
  )
}