import React from 'react';
import { BrowserRouter as Router, Redirect, Route, useHistory } from 'react-router-dom';
import Configs from '../globals/config'
import { Amplify } from 'aws-amplify'
import { IndexPage } from './Index';
import { EventPage } from './Index/EventPage';



export default function RootRouter() {
  Amplify.configure(Configs)

  return (
    <Router>
      <Route exact path="/event/:eventId">
        <EventPage />
      </Route>
      <Route exact path="/:linkId">
        <IndexPage />
      </Route>
      <Route exact path="/">
        <IndexPage />
      </Route>
    </Router>
  );
}
