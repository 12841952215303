import * as React from 'react';
import { useParams, Redirect } from 'react-router-dom';

export const EventPage: React.FC = () => {
  const { eventId } = useParams<{ eventId: string }>()

  const redirect = () => {

    const eventUrl = "https://www.gogoo.app/event?eventId=" + eventId

    location.href = eventUrl
  }

  React.useEffect(()=> {
    redirect()
  }, [])

  return (
    <div className='container' style={{ textAlign: 'center' }}>
      <h1>Henter data...</h1>
    </div>

  )
}