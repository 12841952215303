import React from 'react';
import ReactDOM from 'react-dom';
import RootRouter from './content/RootRouter';
import "./globals/styles.css"

ReactDOM.render(
  <React.StrictMode>
    <RootRouter />
  </React.StrictMode>,
  document.getElementById('root'),
);
