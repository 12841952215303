
type ApiEndpoint = {
    name: string,
    endpoint: string,
    region: string
}

type AppConfigs = {
    API: {
        endpoints: ApiEndpoint[]
    }
}

export const APIName = "PublicApi";

const LocalDevApi = "http://localhost:3000/dev"
const LocalProdApi = "http://localhost:3000/prod"

const DevApi = "https://2zm1v51s9b.execute-api.eu-west-1.amazonaws.com/dev"
const ProdApi = "https://huylvuidgj.execute-api.eu-west-1.amazonaws.com/prod"

export const ResourceUrl = "https://prod-attachments-bucket-gogoo-1.s3.eu-west-1.amazonaws.com/"

let endpoint = ProdApi
if(process.env.REACT_APP_STAGE === "DEV") endpoint = DevApi
if(process.env.REACT_APP_STAGE === "LOCAL_DEV") endpoint = LocalDevApi
if(process.env.REACT_APP_STAGE === "LOCAL_PROD") endpoint = LocalProdApi

const prodConfig: AppConfigs = {
    API: {
        endpoints: [{
            name: APIName,
            endpoint: endpoint + '/',
            region: "eu-west-1"
        }]
    }
}

export default prodConfig