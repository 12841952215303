import { API } from "aws-amplify"

import { APIName } from "./config"
import { KultunautEvent } from "../shared/KultunautTypes";
import { GogooPublicKultuanutEventDetailes } from "../shared/GogoApi";

export type Link = {
  title: string;
  description: string;
  image: string;
  applink: string;
}

const getLinkById = async (id: string): Promise<Link | undefined> => {
  const result = await API.get(APIName, 'link/' + id, {})
  return result
}

const getEventById = async (id: string): Promise<GogooPublicKultuanutEventDetailes | undefined> => {
  const result = await API.get(APIName, 'pubic/detailedevent/' + id, {})
  return result
}


const handleEventClick = (eventId: string | number, withRedirect: boolean) => {
  try {
    setTimeout(function () { document.location.href = "gogoo:///event?id=" + eventId; }, 250);
  } catch {
    if (withRedirect) {
      var userAgent = navigator.userAgent || navigator.vendor
      if (/android/i.test(userAgent)) {
        return setTimeout(function () { document.location.href = "https://play.google.com/store/apps/details?id=app.gogoo.gogoo" }, 250);
      }
      if (/iPad|iPhone|iPod/.test(userAgent)) {
        return setTimeout(function () { document.location.href = "https://apps.apple.com/dk/app/gogoo/id1560958137" }, 250);
      }
      return setTimeout(function () { document.location.href = "https://www.gogoo.app/" }, 250)
    }
  }
}

export default { getLinkById, getEventById, handleEventClick }